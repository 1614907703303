import React from 'react';
import './App.css';
import DarkMode from './components/darkMode/darkMode';

function App() {
  return (
  <div className={`App`}>
    <header className="App-header">
      <DarkMode />
        <p>
          Daniel Rodrigues Portfolio.
        </p>
      </header>
    </div>
  );
}

export default App;
