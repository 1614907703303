import React, { useState } from "react";
import { ReactComponent as Sun } from "./yellow_sun.svg"
import { ReactComponent as Moon } from "./half_moon.svg"
import "./darkMode.css"

const DarkMode = () => {
    const selectedTheme = localStorage.getItem("selectedTheme");

    var selectedDarkTheme = false;

    if (selectedTheme === "dark") {
        selectedDarkTheme = true;
    }

    const [isDarkMode, setIsDarkMode] = useState(selectedDarkTheme);

    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
        if (isDarkMode) {
            setLightMode();
        } else {
            setDarkMode();
        }
    };

    const setDarkMode = () => {
        document.querySelector("body")?.setAttribute("data-theme", "dark");
        localStorage.setItem("selectedTheme", "dark");
    };

    const setLightMode = () => {
        document.querySelector("body")?.setAttribute("data-theme", "light");
        localStorage.setItem("selectedTheme", "light");
    };

    if (selectedTheme === "dark") {
        setDarkMode();
    }
    else {
        setLightMode();
    }

    return (
        <div className="dark-mode-container">
            <button className={`toggle-button ${selectedTheme ? "dark" : "light"}`} onClick={toggleTheme}>
                {selectedTheme === "dark" ? (
                    <>
                        <Sun className="svg-icon" />
                    </>
                ) : (
                    <>
                        <Moon className="svg-icon" />
                    </>
                )}
            </button>
        </div>
    );
};

export default DarkMode;